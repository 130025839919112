@use "@/styles/abstracts" as *;

.spinner {
	width: 1em;
	height: 1em;
	line-height: 1;

	background: linear-gradient(
		165deg,
		rgba(255, 255, 255, 1) 0%,
		rgb(220, 220, 220) 40%,
		rgb(170, 170, 170) 98%,
		rgb(10, 10, 10) 100%
	);
	border-radius: 50%;
	position: relative;

	&::before {
		display: block;
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		border-radius: 50%;
		border-bottom: 0 solid #ffffff05;
		box-shadow:
			0 -10px 20px 20px #ffffff40 inset,
			0 -5px 15px 10px #ffffff50 inset,
			0 -2px 5px #ffffff80 inset,
			0 -3px 2px #ffffffbb inset,
			0 2px 0px #ffffff,
			0 2px 3px #ffffff,
			0 5px 5px #ffffff90,
			0 10px 15px #ffffff60,
			0 10px 20px 20px #ffffff40;
		filter: blur(3px);
		animation: rotate 2s linear infinite;
	}

	&--xs {
		font-size: rem(20);
	}

	&--sm {
		font-size: rem(24);
	}

	&--md {
		font-size: rem(30);
	}

	&--lg {
		font-size: rem(40);
	}

	&--xl {
		font-size: rem(50);
	}

	&--light {
		background: #9a65fe;

		&::before {
			border-bottom: 0 solid #9a65fe;
			box-shadow:
				0 -10px 20px 20px #9a65fe4f inset,
				0 -5px 15px 10px #9a65fe69 inset,
				0 -2px 5px #9a65fea6 inset,
				0 -3px 2px #9a65fe inset,
				0 2px 0px #9a65fe,
				0 2px 3px #9a65fead,
				0 5px 5px #9a65febf,
				0 10px 15px #9a65fe75,
				0 10px 20px 20px #9a65fe42;
		}
	}

	@include breakpoint($sm) {
		transform: scale(0.6);
	}

	@include breakpoint($md) {
		transform: scale(0.8);
	}
	@include breakpoint($lg) {
		transform: scale(1);
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
